import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "TermsAndConditions-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Last updated: August 1st 2019, Effective immediately (2019.08.01) for new customers, effective for all existing Customers as of 2019.11.01.`}</p>
    <h1>{`Maintenance Connection Canada SOFTWARE END USER LICENCE USE AGREEMENT as of August 2019`}</h1>
    <p>{`Thank you for selecting the Maintenance Connection Families of Software (defined below and referred to as "MC Software") offered by Asset Pro Solutions Inc. and/or its subsidiaries, dealers and affiliates (referred to as "MCC," "we," "our," or "us"). Review these licence terms ("Agreement") thoroughly.`}</p>
    <p>{`This Agreement is a legal agreement between you, your employer, employees, consultants, agents and customers as permitted and MCC. By clicking "I HAVE READ AND ACCEPT THE TERMS OF USE" or logging in, or indicating acceptance electronically, installing, accessing or using the Software, you agree to these terms. `}</p>
    <p>{`If you or your employer do not agree to this Agreement, then you may not use the Software. Your use of this Software is your agreement to abide by each of the Terms set forth below. PLEASE READ THESE TERMS CAREFULLY.`}</p>
    <p>{`Maintenance Connection Canada is the operating name, the "Doing Business As" name or division of Asset Pro Solutions, Inc. since 2004 and is legally registered with the Alberta Corporate Registries as a trade name of Asset Pro Solutions, Inc.`}</p>
    <h1>{`GENERAL TERMS`}</h1>
    <h2>{`1. AGREEMENT`}</h2>
    <h3>{`1.1 This Agreement describes the terms governing your use of the MCC Software including content, updates and new releases (collectively, the “Software”). It includes by reference:`}</h3>
    <p>{`MCC's Privacy Statement `}<a parentName="p" {...{
        "href": "https://www.maintenanceconnection.ca/PrivacyPolicy"
      }}>{`https://www.maintenanceconnection.ca/PrivacyPolicy`}</a>{` .`}</p>
    <p>{`Additional Terms and Conditions, which may include those from third parties; and `}</p>
    <p>{`Any terms provided separately to you for the Software, including product or program terms, ordering, activation, payment terms, etc. `}</p>
    <h3>{`1.2 You must be at least 18 years of age to use our Services. By accessing or using our Services you agree that:`}</h3>
    <p>{`You can form a binding contract with MCC;`}</p>
    <p>{`You are not a person who is prohibited from receiving the Services under the laws of the Canada or any other applicable jurisdiction; and`}</p>
    <p>{`You will comply with this Agreement and all applicable local, provincial, national, and international laws, rules, and regulations, including import and export regulations.`}</p>
    <h2>{`2. MC SOFTWARE`}</h2>
    <p>{`The software under this license refers to the following products, collectively called "MC Software":`}</p>
    <p>{`"Maintenance Connection Maintenance Repair and Operations", "Maintenance Connection API", "Maintenance Connection Work Center" all Copyright Maintenance Connection, Inc. and,`}</p>
    <p>{`"Maintenance Connection LoginHub", "Maintenance Connection DataHub", "Maintenance Connection Everywhere", "Maintenance Connection Universal WorkOrder", "Maintenance Connection Mobile", "Maintenance Connection Tablet Hybrid", "Maintenance Connection Everywhere API", "Maintenance Connection Everywhere DataHub"  all Copyright ITIQPro Inc. and`}</p>
    <p>{`any modules or extensions or add-ons to any of the above software and`}</p>
    <p>{`any custom software written for you or your company by MCC, Accruent, LLC, Maintenance Connection, Inc. and/or ITIQPro Inc. and licensed to you by MCC.`}</p>
    <p>{`This software may also be known by many abbreviations. Frequently the "Maintenance Connection" will be abbreviated as "MC" or may be removed entirely from the abbreviation. Everywhere is often abbreviated "e" as in "MCe", Maintenance Connection Express LE is often abbreviated "Express LE", "MCxLE", "xLE" or "MCe", Maintenance Connection Express is often abbreviated "Express". Other abbreviations may be used in and outside the software from time to time.`}</p>
    <h2>{`3. LICENCE GRANT AND RESTRICTIONS`}</h2>
    <h3>{`3.1`}</h3>
    <p>{`The Software is protected by copyright, trade secret, and other intellectual property laws. You are only granted certain limited rights to install and use the Software, and MCC reserves all other rights in the Software not granted to you in writing herein. As long as you meet any applicable payment obligations and comply with this Agreement, MCC grants you a personal, limited, nonexclusive, non transferable, revocable licence to use the Software only for the period of use provided in the ordering and activation terms, as set forth in this Agreement, or in accordance with MCC’s then-current product discontinuation policies, as updated from time to time, and only for the purposes described by MCC for the Software.`}</p>
    <h3>{`3.2`}</h3>
    <p>{`You acknowledge and agree that the Software is licensed, not sold. You agree not to use, nor permit any third party to use, the Software in a manner that violates any applicable law, regulation or this Agreement. You agree you will not: `}</p>
    <p>{`Provide access to or give the Software or any part of the Software to any third party; `}</p>
    <p>{`Reproduce, duplicate, modify, copy, deconstruct, reverse-engineer, sell, trade or resell the Software; `}</p>
    <p>{`Transfer your licence to the Software to any other party;`}</p>
    <p>{`Attempt unauthorized access to any other MCC systems that are not part of the Software;`}</p>
    <p>{`Permit any third party to benefit from the use or functionality of the Software via a rental, lease, timesharing, service bureau, hosting service, or other arrangement; or Make the Software available on any file-sharing or application hosting service.`}</p>
    <h3>{`3.3 RESTRICTIONS`}</h3>
    <p>{`You agree that you are permitted to store, manipulate, analyze, reformat, print, and display the Content only for your internal business use. Unauthorized use, resale or commercial exploitation of the Software and/or the Content in any way is expressly prohibited. You agree not to reverse engineer the Software, or access the Software in order to (i) build a competitive product or Software, (ii) build a product using similar ideas, features, functions or graphics of the Software, or (iii) copy any ideas, features, functions or graphics of the Software. You shall not copy, license, sell, transfer, make available, distribute, or assign this license or the Content to any third-party. You shall not create Internet "links" to the Software or "frame" or "mirror" any Content contained on, or accessible from, the Software on any other server or Internet-based device. You are advised to consult with your broker or other financial representative to verify pricing information prior to the execution of any security trade based upon the Content. Named User licenses cannot be shared or used by more than one individual User but may be reassigned from time to time to new Users who are replacing former Users who have terminated employment with Customer or otherwise changed job status or function such that they no longer require access to the Software.`}</p>
    <h2>{`4. UPGRADES AND SUPPORT AND MAINTENANCE AGREEMENT (SMA)`}</h2>
    <p>{`To be eligible for upgrades and support and continued use, Customer must have an up to date, paid for Support and Maintenance Agreement (referred to as SMA). Your SMA payment includes the right to upgrades, to standard support and, unless otherwise agreed in writing, to continued licensing of this software. Your rate for SMA may vary depending on terms you have including any increased support levels agreed in writing, or prices and terms for the licensing portion of the fee.`}</p>
    <h2>{`5. CHARGES AND PAYMENT of FEES`}</h2>
    <p>{`Customer shall pay all fees or charges to its account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. The initial charges will be defined in the final license agreement. Payments should be made on a recurring basis consistent with the Initial Term. License fees are nonrefundable whether or not User licenses are actively used. Added licenses will be subject to the following: (i) added licenses will be coterminous with the preexisting License Term (either Initial Term or renewal term) (ii) the license fee for the added licenses will be the then current, generally applicable license fee; and (iii) licenses added in the middle of a billing month will be charged in full for that billing month. If you are not purchasing directly from MCC, that is, if you are purchasing through a MCC dealer such as Maintenance Connection, Inc. or MC Global or MC Africa or ABP Solution, your payment arrangements may not be with MCC.  MCC reserves the right to change the fees, applicable charges and usage policies and to introduce new charges at any time, upon at least 30 days prior notice to Customer, which notice may be provided by e-mail.`}</p>
    <p>{`Payments will be billed to you in Canadian dollars or US dollars or other currency as agreed in writing, and your account will be debited when you subscribe and provide your payment information, unless stated otherwise in the program ordering or payment terms on the website for the Software. `}</p>
    <p>{`You must pay with one of the following: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`A valid credit card acceptable to MCC; `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`A valid debit card acceptable to MCC; `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Sufficient funds in a checking or savings account to cover an electronic debit of the payment due; or `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`By another payment option MCC provides to you in writing. `}</p>
      </li>
    </ul>
    <p>{`If your payment and registration information is not accurate, current and complete, and you do not notify us promptly when such information changes, we may suspend or terminate your account, terminate your licence and refuse any further use of the Software. `}</p>
    <p>{`If you do not notify us of updates to your payment method (e.g., credit card expiration date), to avoid interruption of your service, we may participate in programs supported by your card provider (e.g., updater services, recurring billing programs, etc.) to try to update your payment information, and you authorize us to continue billing your account with the updated information that we obtain.`}</p>
    <p>{`MCC will automatically renew your monthly, quarterly, or annual Support and Maintenance Agreement SMA at the then-current rates, unless the Software licence or Subscription is cancelled or terminated under this Agreement. If full payment for SMA is not received by the renewal date, your license to use the software is revoked and will be subject to the then current terms for reactivating.`}</p>
    <p>{`Additional cancellation or renewal terms may be provided to you on the website for the Software.`}</p>
    <h2>{`6. NON-PAYMENT AND SUSPENSION`}</h2>
    <p>{`In addition to any other rights granted to MCC herein, MCC reserves the right to suspend or terminate this Agreement and Customer's access to the Software if Customer's account becomes thirty (30) days or more delinquent (falls into arrears). Delinquent invoices (accounts in arrears) are subject to interest of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is less, plus all expenses of collection plus $150, or the maximum permitted by law, whichever is less. Customer will continue to be charged for User licenses during any period of suspension. If Customer or MCC initiates termination of this Agreement, Customer will be obligated to pay the balance due on Customer's account computed in accordance with the Charges and Payment of Fees section above. Customer agrees that MCC may charge such unpaid fees to Customer's credit card or otherwise bill Customer for such unpaid fees.`}</p>
    <p>{`MCC reserves the right to impose a reconnection fee in the event Customer is suspended and thereafter requests access to the Software. Customer agrees and acknowledges that MCC has no obligation to retain Customer Data and that such Customer Data may be irretrievably deleted if Customer's account is 30 days or more delinquent.`}</p>
    <h2>{`7. CUSTOMER RESPONSIBILITIES`}</h2>
    <p>{`Customer is responsible for any and all activities that occur under Customer's user accounts and shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with use of the Software. Customer shall: (i) notify MCC immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (ii) report to MCC immediately and use reasonable efforts to stop immediately any copying or distribution of Content that is known or suspected by Customer or its Users; (iii) assure that use of the Software shall at all times comply with all applicable local, state, federal, and international laws, regulations, and conventions, including without limitation those related to data privacy, international communications, and the exportation of technical or personal data; and (iv) not impersonate another Maintenance Connection user or provide false identity information to gain access to or use the Software.`}</p>
    <h2>{`8. YOUR PRIVACY AND PERSONAL INFORMATION`}</h2>
    <h3>{`8.1 Personal Information We Receive. We may receive personal information from you:`}</h3>
    <p>{`a. at the time you download and/or register the Software;`}</p>
    <p>{`b. if you connect to the Internet while using the Software (as described below); `}</p>
    <p>{`c. if you connect your account to an online version of the Software or another online version of an MCC product or service; `}</p>
    <p>{`d. you use a web-enabled feature or service, such as a backup or archiving service or an eFiling service; `}</p>
    <p>{`e. allow us to collect Usage Data (defined below) and/or`}</p>
    <p>{`f. you request support services or require technical assistance.`}</p>
    <p>{`By using the Software or taking one of the actions described above, you acknowledge that MCC will process your personal information as described in our Privacy Statement `}<a parentName="p" {...{
        "href": "https://www.maintenanceconnection.ca/PrivacyPolicy"
      }}>{`https://www.maintenanceconnection.ca/PrivacyPolicy`}</a>{`.`}</p>
    <p>{`California Consumer Protection Act. Where applicable, for the purposes of the California Consumer Protection Act (“CCPA”), MCC shall be considered a Business and/or Third Party, as applicable. Where MCC acts as a Third Party, you represent, warrant and covenant that all Personal Information provided or otherwise made available to MCC is done so in compliance with applicable law, and that it has provided all necessary and appropriate notices and opt-outs, and otherwise has all necessary and appropriate rights, to enable MCC to (i) share any and all Personal Information you provided with any MCC company, including MCC Inc. and any parent, subsidiary, affiliate, or related company of MCC Inc. (collectively, the “MCC Family Companies”), and (ii) use any such Personal Information in connection with any and all MCC Family Companies’ internal operations and functions, including, but not limited to, improving such MCC Family Companies’ products and/or services, operational analytics and reporting, internal financial reporting and analysis, audit functions and archival purposes. Notwithstanding the foregoing, the parties agree that the sharing of Personal Information between MCC Family Companies does not constitute a “sale” of such Personal Information under the CCPA. Capitalized terms in this paragraph have the meanings given those terms under the CCPA.`}</p>
    <h2>{`8.2`}</h2>
    <p>{`Multifactor Authentication. You understand and agree that the Software may require multiple sources of information about you to confirm your identity and help ensure the security of your personal use of the MC Software, often referred to as “multi-factor authentication” (“MFA”). Part of the MFA identity authentication and verification process may involve MCC sending text message(s) containing security code(s) to your mobile phone number. You agree to receive these text message(s) from MCC containing security code(s) as part of the MFA process`}</p>
    <h3>{`8.3`}</h3>
    <p>{`Web-Enabled Features. To continue to provide you with the best service, our Desktop, Laptop and/or Mobile versions of the Software are web-enabled products. If you connect to the Internet while you are using the Software, we may deliver product updates, message service information, and help content. We may also allow you to submit your data via web-enabled features, (collectively “Online Service”). If you elect to connect your account to or submit data through any Online Service, we may have access to your data in order to provide you with the service. Data accessed via any Online Service and will be processed in accordance with our Privacy Statement. `}</p>
    <h3>{`8.4`}</h3>
    <p>{`Usage Data. We may collect data about your use of the Software, your computer, its operating system, application software, and any peripheral hardware (collectively, “Usage Data”). The data will be processed in accordance with our Privacy Statement. As this is necessary for practical maintenance and use of the software, this cannot be revoked. There may be certain ways that this can be limited, such as by jurisdiction and some forms of information if agreed in writing.`}</p>
    <h2>{`9. CONTENT`}</h2>
    <h3>{`9.1`}</h3>
    <p>{`You are responsible for your content. You are responsible for all materials ("Content") uploaded, posted or stored through your use of the Software. You grant MCC a worldwide, royalty-free, non-exclusive licence to host and use any Content provided through your use of the Software. Archive your Content frequently. You are responsible for lost or unrecoverable Content. You must provide all required and appropriate warnings, information and disclosures. MCC is not responsible for the Content or data you provide through your use of the Software. Except as permitted by MCC in writing, you agree not to use the Software, nor permit any third party to use, the Software to upload, post, distribute, link to, publish, reproduce, engage in or transmit any of the following, including but not limited to: `}</p>
    <p>{`a. Illegal, fraudulent defamatory, obscene, pornographic, profane, threatening, abusive, hateful, harassing, offensive, inappropriate or objectionable information or communications of any kind, including without limitation conduct that would encourage "flaming" others, or criminal or civil liability under any local, state, federal or foreign law; `}</p>
    <p>{`b. Content that would impersonate someone else or falsely represent your identity or qualifications, or that constitutes a breach of any individual’s privacy; `}</p>
    <p>{`c. Investment opportunities, solicitations, chain letters, pyramid schemes, other unsolicited commercial communication or engage in spamming or flooding; `}</p>
    <p>{`d. Virus, trojan horse, worm or other disruptive or harmful software or data; and `}</p>
    <p>{`e. Any information, software or Content which is not legally yours and without permission from the copyright owner or intellectual property rights owner. `}</p>
    <h3>{`9.2`}</h3>
    <p>{`Community forums. The Software may include a community forum or other social features to exchange Content and information with other users of the Software and the public. Please use respect when you interact with other users. MCC does not support and is not responsible for the content in these community forums. Do not reveal information that you do not want to make public. Users may post hypertext links to content of third parties for which MCC is not responsible. Treat all others with respect.`}</p>
    <h3>{`9.3`}</h3>
    <p>{`MCC may freely use feedback you provide. You agree that MCC may use your feedback, suggestions, or ideas in any way, including in future modifications of the Software, other products or services, advertising or marketing materials. You grant MCC a perpetual, worldwide, fully transferable, sublicensable, non-revocable, fully paid-up, royalty free licence to use the feedback you provide to MCC in any way.`}</p>
    <h3>{`9.4`}</h3>
    <p>{`You may chose to pay a fee for Customer Sponsored Features (referred to as "CSF") unless otherwise agreed in writing, you agree that MCC may use any communication, feedback, suggestions, ideas, and especially any changes to the software done as part of the CSF for development, features, products, services, advertising or marketing materials and you grant MCC a perpetual, universal, fully transferable, sublicensable, non-revocable, fully paid-up royalty free license to MCC for MCC to use in any way.`}</p>
    <h3>{`9.5`}</h3>
    <p>{`MCC may monitor your Content. MCC may, but has no obligation to, monitor content on the Software. We may disclose any information necessary to satisfy our legal obligations, protect MCC or its customers, or operate the Software properly. MCC, in its sole discretion, may refuse to post, remove, or refuse to remove, any content, in whole or in part, alleged to be unacceptable, undesirable, inappropriate, or in violation of this Agreement. `}</p>
    <h2>{`10. ADDITIONAL TERMS`}</h2>
    <h3>{`10.1`}</h3>
    <p>{`9.1 MCC does not give professional advice. Unless specifically included with the Software, MCC is not in the business of providing legal, financial, accounting, tax, health care, real estate or other professional services or advice. Consult the services of a competent professional when you need this type of assistance.`}</p>
    <h3>{`10.2`}</h3>
    <p>{`We may tell you about other MCC and partner services. You may be offered other services, products, or promotions by MCC ("MCC Services"). Additional terms and conditions and fees may apply. With some MCC Services you may upload or enter data such as names, addresses and phone numbers, purchases, etc., to the Internet. You grant MCC permission to use information about your business and experience to help us to provide the MCC Services to you and to enhance the Software. You grant MCC permission to combine your business data, if any, with that of others in a way that does not identify you or any individual personally. You also grant MCC permission to share or publish summary results relating to research data and to distribute or licence such data to third parties. In particular, we routinely share this information with Accruent LLC and ITIQPro Inc., the copyright holders of most of the software we license to you, in order to give customers the best experience both now and in the future, and we routinely share their services and offerings with you.`}</p>
    <h3>{`10.3`}</h3>
    <p>{`Communications. MCC may be required by law to send you communications about the Software or Third Party Products. You agree that MCC may send these communications to you via email or by posting them on our websites.`}</p>
    <h3>{`10.4`}</h3>
    <p>{`You will manage your passwords and accept updates. You are responsible for securely managing your password(s) for access to the Software and to contact MCC If you become aware of any unauthorized access to your account. The Software may periodically be updated with tools, utilities, improvements, third party applications, or general updates to improve the Software. You agree to receive these updates.`}</p>
    <h3>{`10.5`}</h3>
    <p>{`MCC alone (and its licensors, and suppliers where applicable) shall own all right, title and interest, including all related Intellectual Property Rights, in and to the MCC Technologies, the Content and the MC Software. This Agreement is not a sale and does not convey any rights of ownership in or related to the MC Software, MCC Technologies or Intellectual Property owned by MCC or third parties including but not limited to ITIQPro Inc. and Maintenance Connection, Inc.`}</p>
    <h2>{`11. DISCLAIMER OF WARRANTIES`}</h2>
    <h3>{`11.1`}</h3>
    <p>{`YOUR USE OF THE SOFTWARE AND CONTENT IS ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS AGREEMENT, THE SOFTWARE IS PROVIDED "AS IS. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, MCC, ITS AFFILIATES, AND ITS THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY, "SUPPLIERS") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY THAT THE SOFTWARE IS FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, DATA LOSS, NON-INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OR CONTENT IN OR LINKED TO THE SOFTWARE. MCC AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SOFTWARE IS SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER. SOME PROVINCES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. THIS WARRANTY GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY HAVE OTHER RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.`}</p>
    <h3>{`11.2`}</h3>
    <p>{`MCC, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SOFTWARE WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.`}</p>
    <h2>{`12. LIMITATION OF LIABILITY AND INDEMNITY`}</h2>
    <p>{`TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF MCC, ITS AFFILIATES AND SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR THE SOFTWARE DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM. SUBJECT TO APPLICABLE LAW, MCC, ITS AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OR DELAYS OF TELECOMMUNICATIONS, THE INTERNET, SERVERS, ELECTRONIC COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES, SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF SOFTWARE OR HARDWARE THAT DOES NOT MEET MCC SYSTEMS REQUIREMENTS. THE ABOVE LIMITATIONS APPLY EVEN IF MCC AND ITS AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF MCC, ITS AFFILIATES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SOFTWARE AND ITS USE. You agree to indemnify and hold MCC and its Affiliates and Suppliers harmless from any and all claims, liability and expenses, including reasonable attorneys' fees and costs, arising out of your use of the Software or breach of this Agreement (collectively referred to as "Claims"). MCC reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any Claims. You agree to reasonably cooperate as requested by MCC in the defense of any Claims.`}</p>
    <h2>{`13. CHANGES`}</h2>
    <p>{`We reserve the right to change this Agreement at any time, and the changes will be effective when posted on our website for the Software or when we notify you by other means. We may also change or discontinue the Software, in whole or in part. Your continued use of the Software indicates your agreement to the changes.`}</p>
    <h2>{`14. TERMINATION`}</h2>
    <p>{`MCC may immediately, in its sole discretion, and without notice terminate the Software if you fail to comply with this Agreement or if you no longer agree to receive electronic communications or if SMA is not renewed on time. Upon termination you must immediately stop using and delete or destroy all copies of the Software and any outstanding payments will become due. Any termination of this Agreement shall not affect MCC’s rights to any payments due to it. MCC may terminate a free account at any time. Sections 3.2, 8 and 13 through 19 will survive and remain in effect even if the Agreement is terminated, cancelled or rescinded.`}</p>
    <h2>{`15. EXPORT RESTRICTIONS`}</h2>
    <p>{`You acknowledge that the Software is subject to restrictions and controls imposed by the Export and Import Permits Act (Canada) and regulations enacted under the Export and Import Permits Act as well as the U.S. export controls regulations administered by the U.S. Dept. of Commerce (15 CFR, Chapter VII) and any export laws of Belize and that you will comply with all applicable laws and regulations. You will not export or re-export the Software, or portion thereof, directly or indirectly, in violation of the Canadian, U.S. and Belize export administration laws and regulations to any country or end user; or to any end user who you know or have reason to know will utilize them in the design, development or production of nuclear, chemical or biological weapons. You further acknowledge that this Software may include technical data subject to such Canada, U.S. and Belize export regulations.`}</p>
    <h2>{`16. GOVERNING LAW AND JURISDICTION`}</h2>
    <p>{`This Agreement shall be governed by The Province of Alberta and the federal laws of Canada without regard to Canada’s or Alberta’s conflicts of laws provisions. To resolve any legal dispute arising from this Agreement, you and MCC agree to the exclusive jurisdiction of the Province of Alberta Canada. MCC does not represent that the Software is appropriate or available for use in all countries. MCC prohibits accessing materials from countries or states where contents are illegal. You are using the Software on your own initiative and you are responsible for compliance with all applicable laws. `}</p>
    <h2>{`17. LANGUAGE`}</h2>
    <p>{`Except in Quebec, any translation of this Agreement is done for local requirements and in the event of a dispute between the English and any non-English version, the English version of this Agreement shall govern. In Quebec, the French language version of this Agreement shall be equally authoritative. The parties hereby confirm that they have requested that this Agreement and all related documents be drafted in English as well as French. Les parties ont exigé que le présent contrat et tous les documents connexes soient rédigés en anglais comme le français. `}</p>
    <h2>{`18. GENERAL`}</h2>
    <p>{`This Agreement, including Additional Terms below is the entire agreement between you and MCC and replaces all prior understandings, communications and agreements, oral or written, regarding its subject matter. If any court of law, having the jurisdiction, rules that any part of this Agreement is invalid, that section will be removed without affecting the remainder of the Agreement. The remaining terms will be valid and enforceable. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement. You cannot assign or transfer ownership of this Agreement to anyone without written approval of MCC. However, MCC may assign or transfer it without your consent to (a) an affiliate, (b) a company through a sale of assets by MCC or (c) a successor by merger. Any assignment in violation of this Section shall be void. `}</p>
    <h2>{`19. PROVINCE SPECIFIC TERMS`}</h2>
    <p>{`These terms apply only to consumers in the following province. In the event of a conflict between the terms of this Agreement and this Section 15, the terms of this Section 15 shall apply: `}</p>
    <p>{`Quebec Consumers. If you are a Québec Consumer, the following terms apply to you:`}</p>
    <p>{`Disclaimer of warranties. The sole warranty applicable to this Agreement and the Services is the mandatory legal warranty provided under the Civil Code of QuÉbec and any warranty provided for under the Consumer Protection Act (Québec). `}</p>
    <p>{`Modifications. The provisions relating to the Services, the price and payment terms for the Services, the party providing the Services, renewal and termination rights, and any provision of the Privacy Statement may be modified or amended upon 30 days prior written notice setting out (i) the new clause or the amended clause and the clause as it read formerly, and (ii) the date of the coming into force of the such modification or amendment. If we do not allow you to maintain this Agreement unamended, you will have the right to terminate this Agreement during the notice period.`}</p>
    <p>{`Termination. Termination of this Agreement by MCC without default by you is only effective upon 60 days prior written notice.`}</p>
    <p>{`Governing law. Any disputes arising out of or related to this Agreement or the Services shall be governed by the laws of Province of Québec and the laws of Canada applicable therein. `}</p>
    <h2>{`20. Trademarks/Copyrights`}</h2>
    <p>{`In Canada "Maintenance Connection" and the Maintenance Connection Canada logo are trademarks and/or service of MCC`}</p>
    <p>{`Worldwide "Mainteance Connection Everywhere", "MCe", the Maintenance Connection Everywhere logo, "MCxLE","MCeLE", "MCC", "Maintenance Connection Canada", "MCEverywhere Maintenance Connection Express Limited Internet Edition", "Maintenance Connection Express Luxury Edition", "Maintenance Connection Everywhere Luxury Edition", "APS" are, by license to ITIQPro and agreement by Maintenance Connection, Inc. the trademark of MCC.`}</p>
    <p>{`Outside of Canada, "Maintenance Connection" and the Maintenance Connection so called 'check mark' logo is the trademark of Maintenance Connection, Inc.`}</p>
    <p>{`Microsoft, Windows, and Microsoft Internet Explorer are registered trademarks of Microsoft Corporation. Any other product names, trademarks, service marks or registered marks, symbols, trade names, company names and/or logos which appear within this product are the property of their respective owners. Such marks are protected by Canadian law on trademark and unfair competition and may also be registered in the Canadian Intellectual Property Office and/or in the U.S. Patent and Trademark Office.`}</p>
    <h2>{`21. OTHER DEFINITIONS`}</h2>
    <p>{`As used in this Agreement and in any Order Forms now or hereafter associated herewith: `}</p>
    <p>{`"Agreement" means this Softwares agreement, and any Order Forms, whether written or submitted online via any online process, and any materials available on the Maintenance Connection Canada website, MaintenanceConnection.ca, specifically incorporated by reference herein, as such materials, including the terms of this Agreement, may be updated by Maintenance Connection Canada from time to time in its sole discretion;`}</p>
    <p>{`"Cloud" means software and hardware, either physical or virtualized, on a server that is not in the Customer's physical location. Maintenance Connection Canada provides a variety of Cloud based options for SaaS hosting of the Maintenance Connection family of software;`}</p>
    <p>{`"Content" means the audio and visual information, documents, software, products and Softwares contained or made available to Customer in the course of using the Software;`}</p>
    <p>{`"Customer, You or Customer's" means the individual or legal entity, its directors, officers, affiliates agents, and employees, as identified in the registration and identification data provided to Maintenance Connection Canada via this web site;`}</p>
    <p>{`"Customer Data" means any data, information or material provided or submitted by Customer to Maintenance Connection Canada in the course of utilizing or debugging the Software or providing any other support to Customer;`}</p>
    <p>{`"Effective Date" means the earlier of either the date this Agreement is accepted by selecting the "OK" option presented on the screen where this Agreement is displayed or the date Customer begins using the Software;`}</p>
    <p>{`"Initial Term" means the period during which Customer is obligated to pay for the Software equal to the billing frequency selected by Customer during the subscription process, i.e. if the billing frequency is quarterly, the Initial Term is one quarter;`}</p>
    <p>{`"Intellectual Property Rights" means unpatented inventions, patent applications, patents, design rights, copyrights, trademarks, Software marks, trade names, domain name rights, mask work rights, know-how and other trade secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar nature; "License Administrator(s)" means those Users designated by Customer who are authorized to purchase licenses online using the Online Order Center or by executing written Order Forms;`}</p>
    <p>{`"License Term(s)" means the period(s), excluding the Initial Term, during which a specified number of Users are licensed to use the Software pursuant to the Order Form(s);`}</p>
    <p>{`"Leasing" means that you have, in exchange for agreed compensation to MCC, the right to use the software under this license;`}</p>
    <p>{`"Order Form(s)" means the initial subscription for the Software and any subsequent order forms submitted online, specifying, among other things, the number of licenses and other Softwares contracted for, the applicable fees, the billing period, and other charges as agreed to between the parties and each such Order Form shall be incorporated into and become a part of this Agreement. In the event of any conflict between the terms of this Agreement and the terms of any such Order Form, the terms of this Agreement shall prevail;`}</p>
    <p>{`"Online Order Center" means Maintenance Connection Canada's online application that allows the License Administrator designated by Customer to, among other things, add additional Users to the Software;`}</p>
    <p>{`"Rental" has the same meaning as "Leasing" in this contract;`}</p>
    <p>{`"Maintenance Connection Technology" in this agreement means all of Maintenance Connection Canada's proprietary technology (including software, hardware, products, processes, algorithms, user interfaces, know-how, techniques, designs and other tangible or intangible technical material or information) made available to Customer by Maintenance Connection Canada in providing the Software;`}</p>
    <p>{`"SaaS" means Software as a Service, whereby a Customer pays fees to have software, in this case Maintenance Connection family of software along with other necessary software, hosted on a Cloud server. Until recently this was called by other names including "Software plus Services";`}</p>
    <p>{`"Service(s)" means the specific edition of Maintenance Connection's online customer relationship management, billing, data analysis, and other corporate ERP Softwares identified during the ordering process, developed, operated, and maintained by Maintenance Connection accessible via `}<a parentName="p" {...{
        "href": "http://www.maintenanceconnection.ca"
      }}>{`http://www.maintenanceconnection.ca`}</a>{` or another designated web site or IP address or ancillary Softwares rendered to Customer by Maintenance Connection Canada, to which Customer is being granted access under this Agreement, including the Maintenance Connection Technology and the Content;`}</p>
    <p>{`"SMA" means a Support and Maintenance Agreement. To receive upgrades and ongoing support, Customer must have a SMA in place, fully paid for;`}</p>
    <p>{`"System Administrator(s)" means those Users designated by Customer who are authorized to create User accounts and otherwise administer Customer's use of the Software;`}</p>
    <p>{`"User(s)" means  Customer's, employees, representatives, consultants, contractors or agents who are authorized to use the Software and have been supplied user identifications and passwords by Customer (or by Maintenance Connection Canada at Customer's request).`}</p>
    <h2>{`22 Questions or Additional Information:`}</h2>
    <p>{`If you have questions regarding this Agreement or wish to obtain additional information, please send an e-mail to `}<a parentName="p" {...{
        "href": "mailto:info@maintenanceconnection.ca"
      }}>{`info@maintenanceconnection.ca`}</a>{`.`}</p>
    <h1>{`----------------------------`}</h1>
    <h1>{`For reference only:`}</h1>
    <p>{`The following were the Terms and Conditions prior to August 2014.`}</p>
    <h1>{`----------------------------`}</h1>
    <h1>{`Maintenance Connection Canada's Software Terms and Conditions`}</h1>
    <p>{`Welcome to the Maintenance Connection Canada's product offerings. The terms and conditions set forth below (the "Terms") govern the use of this Site and are legally binding on you as the user, including your employer, employees, consultants, agents and customers as permitted. If you do not agree with one or more of the Terms, do not access or otherwise use this Software or any information contained in this Software. Your use of this Software is your agreement to abide by each of the Terms set forth below. PLEASE READ THESE TERMS CAREFULLY. `}</p>
    <p>{`Maintenance Connection is a Trademark of Maintenance Connection Canada in Canada. "Maintenance Connection" refers generically as well to a range of products and Softwares written and owned by Maintenance Connection, Inc., Maintenance Connection Canada, and partners. Maintenance Connection, Inc. and Maintenance Connection Canada also have copyrights on a variety of software in the Maintenance Connection family. These Terms and Conditions refer to software copyright by Maintenance Connection Canada under license through Tigre Inc. `}</p>
    <p>{`Maintenance Connection TERMS OF USE: BY CLICKING THE "I HAVE READ AND ACCEPT THE TERMS OF USE" BOX IN OUR SOFTWARE, YOU ACCEPT, AND AGREE TO BE BOUND BY ALL OF THE TERMS, WITHOUT LIMITATION OR QUALIFICATION. IF AFTER READING THIS AGREEMENT, YOU DO NOT WISH TO AGREE TO ALL OF ITS TERMS, DO NOT CONTINUE, THE REGISTRATION PROCESS WILL TERMINATE.`}</p>
    <p>{`You may be, in addition, subject to other Terms and Conditions, most commonly: If you are running on one of our Software as a Service ("SaaS") servers in the "Cloud".;`}</p>
    <h2>{`Welcome`}</h2>
    <p>{`As part of the Software, Maintenance Connection Canada will provide Customer with use of the Software, including a browser interface and data encryption,  transmission, access and storage. Customer's registration for, or use of, the Software shall be deemed to be Customer's agreement to abide by this Agreement including any materials available on any Maintenance Connection website incorporated by reference herein, including but not limited to the Privacy and Security Statements. For reference, a Definitions section is included at the end of this Agreement.`}</p>
    <h2>{`1. Charges and Payment of Fees`}</h2>
    <p>{`Customer shall pay all fees or charges to its account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. The initial charges will be defined in the final license agreement. Payments should be made on a recurring basis consistent with the Initial Term. License fees are nonrefundable whether or not User licenses are actively used. Added licenses will be subject to the following: (i) added licenses will be coterminous with the preexisting License Term (either Initial Term or renewal term) (ii) the license fee for the added licenses will be the then current, generally applicable license fee; and (iii) licenses added in the middle of a billing month will be charged in full for that billing month. If you are not purchasing directly from Maintenance Connection Canada, that is, if you are purchasing through a Maintenance Connection Canada dealer such as Maintenance Connection, Inc. or MC Global or MC Africa or ABP Solution, your payment arrangements may not be with Maintenance Connection Canada.  Maintenance Connection Canada reserves the right to change the fees, applicable charges and usage policies and to introduce new charges at any time, upon at least 30 days prior notice to Customer, which notice may be provided by e-mail.`}</p>
    <h2>{`2. Upgrades and Support and Maintenance Agreement (SMA)`}</h2>
    <p>{`To be eligible for upgrades and support, Customer must have an up to date, paid for Support and Maintenance Agreement ("SMA").`}</p>
    <h2>{`3. Billing and Renewal`}</h2>
    <p>{`Maintenance Connection Canada charges and collects in advance for use of our support and maintenance agreement, hereinafter referred to as SMA. You will be billed or your credit card will be charged (a) every quarter for quarterly licenses, (b) bi-annually for bi-annual licenses, or (c) each year on the subsequent anniversary for annual licenses. The renewal charge will be equal to the then-current number of total User licenses times the then-current license fee in effect at the time of renewal. Fees for other Softwares will be charged on an as-quoted basis. Your fees are exclusive of all taxes,levies, or duties imposed by taxing authorities, and Customer shall be responsible for payment of all such taxes, levies, or duties, excluding only
Canadian (federal or provincial) taxes based solely on Maintenance Connection Canada's income.`}</p>
    <p>{`Customer agrees to provide Maintenance Connection Canada with complete and accurate billing and contact information. This information includes Customer's legal company name, street address, e-mail address, and name and telephone number of an authorized billing contact and License Administrator. Customer agrees to update this information within 30 days of any change to it. If the contact information Customer has provided is false or fraudulent, Maintenance Connection Canada reserves the right to terminate Customer's access to any Softwares or upgrades in addition to any other legal remedies.`}</p>
    <p>{`For credit card payers, invoices will be generated at the start of a license or billing period and Customer's credit card will be charged simultaneously. If paying by other means, invoices will be generated at the start of a license or billing period and approximately one month or more in advance of the start of any renewal or subsequent billing period and shall be due within 30 days. Customer's account will be considered delinquent (in arrears) if payment in full is not received by the license or billing period start date.`}</p>
    <p>{`Unless Maintenance Connection Canada in its discretion determines otherwise: (i) entities with headquarters and a majority of users inside of Canada will be billed in Canadian dollars and subject to Canadian payment terms and pricing schemes ("Canadian Customers"); and (iii) all other entities will be billed in U.S. dollars unless otherwise agreed in writing and be subject to either Canadian or non-Canadian payment terms and pricing schemes at the discretion of Maintenance Connection Canada ("Non-Canadian Customers").`}</p>
    <p>{`If Customer believes that the bill is incorrect, Customer must contact us in writing within 60 days of the invoice date of the invoice containing the amount in question to be eligible to receive an adjustment or credit.`}</p>
    <h2>{`4. Non-Payment and Suspension`}</h2>
    <p>{`In addition to any other rights granted to Maintenance Connection Canada herein, Maintenance Connection Canada reserves the right to suspend or terminate this Agreement and Customer's access to the Software if Customer's account becomes thirty (30) days or more delinquent (falls into arrears). Delinquent invoices (accounts in arrears) are subject to interest of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is less, plus all expenses of collection plus $150, or the maximum permitted by law, whichever is less. Customer will continue to be charged for User licenses during any period of suspension. If Customer or Maintenance Connection Canada initiates termination of this Agreement, Customer will be obligated to pay the balance due on Customer's account computed in accordance with the Charges and Payment of Fees section above. Customer agrees that Maintenance Connection Canada may charge such unpaid fees to Customer's credit card or otherwise bill Customer for such unpaid fees.`}</p>
    <p>{`Maintenance Connection Canada reserves the right to impose a reconnection fee in the event Customer is suspended and thereafter requests access to the Software. Customer agrees and acknowledges that Maintenance Connection Canada has no obligation to retain Customer Data and that such Customer Data may be irretrievably deleted if Customer's account is 30 days or more delinquent.`}</p>
    <h2>{`5. Privacy & Security`}</h2>
    <p>{`Maintenance Connection Canada's privacy and security policies may be viewed at `}<a parentName="p" {...{
        "href": "http://www.maintenanceconnection.ca/PrivacyPolicy"
      }}>{`http://www.maintenanceconnection.ca/PrivacyPolicy`}</a>{`. Maintenance Connection Canada reserves the right to modify its privacy and security policies in its reasonable discretion from time to time.`}</p>
    <h2>{`6. License Grants`}</h2>
    <p>{`Maintenance Connection Canada grants Customer a non-exclusive, non-transferable worldwide right to use the Software, solely for Customer's own internal business purposes subject to the terms of this Agreement. Subject to the terms of this Agreement. Customer shall not (i) license, sublicense, sell, resell, transfer, assign, distribute or otherwise make available to any third party the Software or the Content, (ii) modify or make derivative works based upon the Maintenance Connection Canada Technology or the Content; (iii) commercially exploit the Software or the Content in any way, or (iv) create Internet "links" to the Software or "frame" or "mirror" any Content contained in, or accessible from, the Software on any other server, wireless or Internet-based device unless otherwise authorized in writing. All rights not expressly granted to Customer are reserved by Maintenance Connection Canada and its licensors.`}</p>
    <h2>{`7. Restrictions`}</h2>
    <p>{`You are permitted to store, manipulate, analyze, reformat, print, and display the Content only for your internal business use. Unauthorized use, resale or commercial exploitation of the Software and/or the Content in any way is expressly prohibited. You agree not to reverse engineer the Software, or access the Software in order to (i) build a competitive product or Software, (ii) build a product using similar ideas, features, functions or graphics of the Software, or (iii) copy any ideas, features, functions or graphics of the Software. You shall not copy, license, sell, transfer, make available, distribute, or assign
this license or the Content to any third-party. You shall not create Internet "links" to the Software or "frame" or "mirror" any Content contained on, or accessible from, the Software on any other server or Internet-based device. You are advised to consult with your broker or other financial representative to verify pricing information prior to the execution of any security trade based upon the Content. Named User licenses cannot be shared or used by more than one individual User but may be reassigned from time to time to new Users who are replacing former Users who have terminated employment with Customer or otherwise changed job status or function such that they no longer require access to the Software.`}</p>
    <h2>{`8. Third-Party Interaction`}</h2>
    <p>{`Customer shall not (i) license, sublicense, sell, resell, transfer, assign, distribute or otherwise make available to any third party the Software or the Content, (ii) modify or make derivative works based upon the Maintenance Connection Technology or the Content; (iii) commercially exploit the Software or the Content in any way, or (iv) create Internet "links" to the Software or "frame" or "mirror" any Content contained in, or accessible from, the Software on any other server, wireless or Internet-based device.`}</p>
    <h2>{`9. Customer Responsibilities`}</h2>
    <p>{`Customer is responsible for any and all activities that occur under Customer's user accounts and shall abide by all applicable local, state, national and foreign laws, treaties and regulations in connection with use of the Software. Customer shall: (i) notify Maintenance Connection Canada immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (ii) report to Maintenance Connection Canada immediately and use reasonable efforts to stop immediately any copying or distribution of Content that is known or suspected by Customer or its Users; (iii) assure that use of the Software shall at all times comply with all applicable local, state, federal, and international laws, regulations, and conventions, including without limitation those related to data privacy, international communications, and the exportation of technical or personal data; and (iv) not impersonate another Maintenance Connection user or provide false identity information to gain access to or use the Software.`}</p>
    <h2>{`10. Account Information and Data`}</h2>
    <p>{`All data submitted by Customer to the Software, whether posted by Customer or by third parties, shall remain the sole property of Customer or such third parties, as applicable, unless specifically notified in advance. Customer, not Maintenance Connection Canada, shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness and copyright of all Customer Data, and Maintenance Connection Canada shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Data.`}</p>
    <h2>{`11. Termination/Reduction in Number of Licenses`}</h2>
    <p>{`This Agreement commences on the Effective Date. The Initial Term of this Agreement will be three months, six months, or one year as determined by Customer's agreement with Maintenance Connection Canada, if not stated in writing, the term shall be one year, commencing on the date Customer agrees to pay for the Software by completing the online subscription form. This Agreement will automatically renew, for a term equal in duration to the Initial Term, upon the expiration of the Initial Term or any renewal term. Either party may terminate this Agreement or reduce the number of licenses, effective upon the expiration of the then current term, by notifying the other party in writing at least five (5) business days prior to the date of the invoice for the following term. In the case of free trials, notifications provided through the Software indicating the remaining number of days in the free trial shall constitute notice of termination. In the event that Customer terminates this Agreement (other than by reason of Customer's breach), if the trial was done on Maintenance Connection Canada servers, Maintenance Connection Canada will make available to Customer a file of its Customer Data within 30 days of termination if Customer so requests at the time notice of termination is given. Customer agrees and acknowledges that Maintenance Connection Canada has no obligation to retain the Customer Data, and may delete such Customer Data, more than 30 days after termination.`}</p>
    <h2>{`12. Termination for Cause`}</h2>
    <p>{`Any breach of Customer's payment obligations or unauthorized use of the Maintenance Connection Technology or Software will be deemed a material breach of this Agreement. Maintenance Connection Canada, in its sole discretion, may terminate Customer's password, account or use of the Software if Customer breaches or otherwise fail to comply with this Agreement. In addition, Maintenance Connection Canada may terminate a free account if Customer does not first log on within 30 days after registration or if 90 days have passed since Customer last logged on. Customer agrees and acknowledges that Maintenance Connection Canada has no obligation to retain the Customer Data, and will delete such Customer Data, if Customer has materially breached this Agreement, including but not limited to failure to pay outstanding fees, and such breach has not been cured within 30 days of notice of such breach.`}</p>
    <h2>{`13. Maintenance Connection Ownership`}</h2>
    <p>{`Maintenance Connection Canada alone (and its licensors, where applicable) shall own all right, title and interest, including all related Intellectual Property Rights, in and to the Maintenance Connection Canada Technologies, the Content and the Software and any suggestions, ideas, enhancement requests, feedback, recommendations or other information provided by Customer or any other party relating to the Software. This Agreement is not a sale and does not convey any rights of ownership in or related to the Software, Maintenance Connection Technologies or Intellectual Property owned by Maintenance Connection Canada to Customer. The Maintenance Connection name, the various Maintenance Connection Canada and Maintenance Connection logos, and the product names associated with the Software are trademarks of Maintenance Connection Canada or third parties, and no right or license is granted to use them. This includes where customers have paid for customer sponsored features or custom changes to code or procedures or reports or rules or SQL, where the customer is paying to have access to have these new features, not to gain Intellection Property of the changes.`}</p>
    <h2>{`14. Third Party Rights`}</h2>
    <p>{`During use of the Software, Customer may enter into correspondence with, purchase goods and/or Softwares from, or participate in promotions of advertisers or sponsors showing their goods and/or Softwares through the Software. Any such activity, and any terms, conditions, warranties or representations associated with such activity, is solely between Customer and the applicable third-party. Maintenance Connection Canada and its licensors shall have no liability, obligation or responsibility for any such correspondence, purchase or promotion between Customer and any such third-party. Maintenance Connection Canada does not endorse any sites on the Internet that are linked through the Software. Maintenance Connection Canada is providing these links to Customer only as a matter of convenience, and in no event shall Maintenance Connection Canada or its licensors be responsible for any content, products, or other materials on or available from such sites. Maintenance Connection Canada provides its Software and Services to Customer pursuant to the terms of this Agreement. Customer recognizes, however, that certain third-party providers of ancillary software, hardware, or Softwares may require Customer's agreement to additional or different license or other terms prior to Customer's use of or access to such software, hardware, or Softwares on behalf of Customer.`}</p>
    <h2>{`15. Representation & Warranties`}</h2>
    <p>{`Each party represents and warrants that it has the legal power and authority to enter into this Agreement. Maintenance Connection Canada represents and warrants that it will provide the Software in a manner consistent with general industry standards reasonably applicable to the provision thereof and that the Software will perform substantially in accordance with the online Maintenance Connection help documentation under normal use and circumstances. Customer represents and warrants that it has not falsely identified itself nor provided any false information to gain access to the Software and that Customer's billing information is correct.`}</p>
    <h2>{`16. Mutual Indemnification`}</h2>
    <p>{`Customer shall indemnify and hold Maintenance Connection Canada, its licensors and each such party's parent organizations, subsidiaries, affiliates, officers, directors, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with: (i) a claim alleging that use of the Customer Data infringes the rights of, or has caused harm to, a third party; (ii) a claim, which if true, would constitute a violation by Customer of the representations and warranties; or (iii) a claim  arising from the breach by Customer or Users of this Agreement, provided in any such case that Maintenance Connection Canada (i) gives written notice of the claim promptly to Customer (ii) gives Customer sole control of the defense and settlement of the claim (provided that Customer may not settle or defend any claim unless it unconditionally releases Maintenance Connection Canada of all liability and such settlement does not affect Maintenance Connection Canada's business or Software); (iii) provides to Customer all available information and assistance; and (iv) has not compromised or settled such claim.`}</p>
    <p>{`Maintenance Connection Canada shall indemnify and hold Customer and its parent organizations, subsidiaries, affiliates, officers, directors, employees, attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with: (i) a claim alleging that the Software directly infringes a copyright, a Canadian patent issued as of the Effective Date, or trademark of a third party; (ii) a claim, which if true, would constitute a violation of Maintenance Connection Canada the representations or warranties; or (iii) a claim arising from breach of this Agreement by Maintenance Connection Canada; provided that Customer (i) promptly gives written notice of the claim to Maintenance Connection Canada; (ii) gives Maintenance Connection Canada sole control of the defense and settlement of the claim (provided that Maintenance Connection Canada may not settle or defend any claim unless it unconditionally releases Customer of all liability); (iii) provides to Maintenance Connection Canada all available information and assistance; and (iv) has not compromised or settled such claim. Maintenance Connection Canada shall have no indemnification obligation, and Customer shall indemnify Maintenance Connection Canada pursuant to this Agreement, for claims arising from any infringement arising from the combination of the Software with any of Customer's products, Software, hardware or business process(s).`}</p>
    <h2>{`17. Disclaimer of Warranties`}</h2>
    <p>{`Maintenance Connection Canada AND their LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR GUARANTY AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, TRUTH,  AVAILABILITY, ACCURACY OR COMPLETENESS OF THE Software OR ANY CONTENT; Maintenance Connection Canada and their LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE Software WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA, (B) THE Software WILL MEET CUSTOMER'S REQUIREMENTS OR EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY PRODUCTS, SoftwareS, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY CUSTOMER THROUGH THE Software WILL MEET CUSTOMER'S REQUIREMENTS OR EXPECTATIONS, (E) ERRORS OR DEFECTS WILL BE CORRECTED, OR (F) THE Software OR THE SERVER(S) THAT MAKE THE Software AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; (III) THE Software AND ALL CONTENT IS PROVIDED TO CUSTOMER STRICTLY ON AN "AS IS" BASIS; AND (IV) ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY  Maintenance Connection Canada AND ITS LICENSORS.`}</p>
    <h2>{`18. Internet Delays`}</h2>
    <p>{`Maintenance Connection Canada'S SERVICES MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC  COMMUNICATIONS. Maintenance Connection Canada IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM SUCH PROBLEMS.`}</p>
    <h2>{`19. Limitation of Liability`}</h2>
    <p>{`IN NO EVENT SHALL EITHER PARTY'S AGGREGATE LIABILITY EXCEED THE AMOUNTS ACTUALLY PAID BY AND/OR DUE FROM CUSTOMER IN THE TWELVE (12) MONTH PERIOD  IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. IN NO EVENT SHALL EITHER PARTY AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY INDIRECT,
PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS Software, INCLUDING BUT NOT LIMITED TO THE USE OR INABILITY TO USE THE Software, OR FOR  ANY CONTENT OBTAINED FROM OR THROUGH THE Software, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF CAUSE IN THE CONTENT, EVEN IF THE PARTY FROM WHICH DAMAGES ARE BEING SOUGHT OR SUCH PARTY'S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.`}</p>
    <h2>{`20. Additional Rights`}</h2>
    <p>{`Certain provinces and/or jurisdictions do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, so the exclusions set forth above may not apply to Customer.`}</p>
    <h2>{`21. Local Laws and Export Control`}</h2>
    <p>{`This site provides Software and uses software and technology that may be subject to Canadian export controls. The user of this site ("User") acknowledges and agrees that the site shall not be used, and none of the underlying information, software, or technology may be transferred or otherwise exported or re-exported to any countries to which Canada maintains an embargo (collectively, "Embargoed Countries"), or to or by a national or resident thereof, or any person or entity that is prohibited by Canadian law. The lists of Embargoed Countries and other prohibitions are subject to change without notice. By using this site, User represents and warrants that it is not located in, under the control of, or a national or resident of an Embargoed Country or prohibited user. User agrees to comply strictly with all Canadian export laws and assumes sole responsibility for obtaining licenses to export or re-export as may be required.`}</p>
    <p>{`This site may use encryption technology that is subject to licensing requirements under Canadian regulations.`}</p>
    <p>{`Maintenance Connection Canada and their licensors make no representation that the Software is appropriate or available for use in other locations. If Customer uses the Software from outside of Canada, the United States of America and/or Costa Rica, Customer is solely responsible for compliance with all applicable laws, including without limitation export and import regulations of other countries. Any diversion of the Content contrary to Canadian or United States law is prohibited. None of the Content, nor any information acquired through the use of the Software, is or will be used for nuclear activities, chemical or biological weapons, or missile projects, unless specifically authorized by the Canadian Government and/or appropriate body(ies) appropriate for such purposes.`}</p>
    <h2>{`22. Notice`}</h2>
    <p>{`Maintenance Connection Canada may give notice by means of a general notice on the Software, electronic mail to Customer's e-mail address on record Maintenance Connection Canada's account information, or by written communication sent by first class mail or pre-paid post to Customer's address on record in Maintenance Connection Canada's account information. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting (if sent by first class mail or pre-paid post) or 12 hours after sending (if sent by email). Customer may give notice to Maintenance Connection Canada (such notice shall be deemed given when received by Maintenance Connection Canada) at any time by any of the following: by confirmed email at `}<a parentName="p" {...{
        "href": "mailto:info@MaintenanceConnection.ca"
      }}>{`info@MaintenanceConnection.ca`}</a>{` or letter delivered by nationally recognized overnight delivery to 282135 Symon's Valley Road, Rockyview County, Alberta, Canada or first class postage prepaid mail to Maintenance Connection Canada at the address provided by Maintenance Connection Canada, currently: Box 516, Crossfield Alberta for mail or 282135 Symon's Valley Road, Rockyview County, Alberta for non-mail delivery addressed to: Chief Financial Officer.`}</p>
    <h2>{`23. Modification to Terms`}</h2>
    <p>{`Maintenance Connection Canada reserves the right to change the terms and conditions of this Agreement or its policies relating to the Software at any time and shall notify Customer by posting an updated version of this Agreement on the Software. Customer is responsible for regularly reviewing this Agreement. Continued use of the Software after any such changes shall constitute Customer's consent to such changes.`}</p>
    <h2>{`24. Assignment`}</h2>
    <p>{`This Agreement may not be assigned by Customer without the prior written approval of Maintenance Connection Canada but may be assigned by Maintenance Connection Canada to (i) a parent or subsidiary, (ii) an acquirer of assets, or (iii) a successor by merger. Any purported assignment in violation of this section shall be void.`}</p>
    <h2>{`25. General`}</h2>
    <p>{`With respect to the Canadian Customers and Non-Canadian Customers this Agreement shall be governed by Alberta law and controlling Canadian federal law, without regard to the choice or conflicts of law provisions of any jurisdiction, and any disputes, actions, claims or causes of action arising out of or in connection with this Agreement or the Software shall be subject to the exclusive jurisdiction of the provincial and federal courts located in Calgary, Alberta. Unless otherwise provided by Maintenance Connection Canada in its discretion. No text or information set forth on any other purchase order, preprinted form or document (other than an Order Form, if applicable) shall add to or vary the terms and conditions of this Agreement. If any provision of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then such provision(s) shall be construed, as nearly as possible, to reflect the intentions of the invalid or unenforceable provision(s), with all other provisions remaining in full force and effect. No joint venture, partnership, employment, or agency relationship exists between Customer and AMaintenance Connection Canada as a result of this agreement or use of the Software. The failure of
Maintenance Connection Canada to enforce any right or provision in this Agreement shall not constitute a waiver of such right or provision unless acknowledged and agreed to by Maintenance Connection Canada in writing. This Agreement, together with any applicable Order Form, comprises the entire agreement between Customer and Maintenance Connection Canada and supersedes all prior or contemporaneous negotiations, discussions or agreements, whether written or oral, between the parties regarding the subject matter contained herein.`}</p>
    <h2>{`26. Definitions`}</h2>
    <p>{`As used in this Agreement and in any Order Forms now or hereafter associated herewith: `}</p>
    <p>{`"Agreement" means this Softwares agreement, and any Order Forms, whether written or submitted online via any online process, and any materials available on the Maintenance Connection Canada website, MaintenanceConnection.ca, specifically incorporated by reference herein, as such materials, including the terms of this Agreement, may be updated by Maintenance Connection Canada from time to time in its sole discretion;`}</p>
    <p>{`"Cloud" means software and hardware, either physical or virtualized, on a server that is not in the Customer's physical location. Maintenance Connection Canada provides a variety of Cloud based options for SaaS hosting of the Maintenance Connection family of software;`}</p>
    <p>{`"Content" means the audio and visual information, documents, software, products and Softwares contained or made available to Customer in the course of using the Software;`}</p>
    <p>{`"Customer, You or Customer's" means the individual or legal entity, its directors, officers, affiliates agents, and employees, as identified in the registration and identification data provided to Maintenance Connection Canada via this web site;`}</p>
    <p>{`"Customer Data" means any data, information or material provided or submitted by Customer to Maintenance Connection Canada in the course of utilizing or debugging the Software or providing any other support to Customer;`}</p>
    <p>{`"Effective Date" means the earlier of either the date this Agreement is accepted by selecting the "OK" option presented on the screen where this Agreement is displayed or the date Customer begins using the Software;`}</p>
    <p>{`"Initial Term" means the period during which Customer is obligated to pay for the Software equal to the billing frequency selected by Customer during the subscription process, i.e. if the billing frequency is quarterly, the Initial Term is one quarter;`}</p>
    <p>{`"Intellectual Property Rights" means unpatented inventions, patent applications, patents, design rights, copyrights, trademarks, Software marks, trade names, domain name rights, mask work rights, know-how and other trade secret rights, and all other intellectual property rights, derivatives thereof, and forms of protection of a similar nature; "License Administrator(s)" means those Users designated by Customer who are authorized to purchase licenses online using the Online Order Center or by executing written Order Forms;`}</p>
    <p>{`"License Term(s)" means the period(s), excluding the Initial Term, during which a specified number of Users are licensed to use the Software pursuant to the Order Form(s);`}</p>
    <p>{`"Order Form(s)" means the initial subscription for the Software and any subsequent order forms submitted online, specifying, among other things, the number of licenses and other Softwares contracted for, the applicable fees, the billing period, and other charges as agreed to between the parties and each such Order Form shall be incorporated into and become a part of this Agreement. In the event of any conflict between the terms of this Agreement and the terms of any such Order Form, the terms of this Agreement shall prevail;`}</p>
    <p>{`"Online Order Center" means Maintenance Connection Canada's online application that allows the License Administrator designated by Customer to, among other things, add additional Users to the Software;`}</p>
    <p>{`"Maintenance Connection Technology" in this agreement means all of Maintenance Connection Canada's proprietary technology (including software, hardware, products, processes, algorithms, user interfaces, know-how, techniques, designs and other tangible or intangible technical material or information) made available to Customer by Maintenance Connection Canada in providing the Software;`}</p>
    <p>{`"SaaS" means Software as a Service, whereby a Customer pays fees to have software, in this case Maintenance Connection family of software along with other necessary software, hosted on a Cloud server. Until recently this was called by other names including "Software plus Services";`}</p>
    <p>{`"Service(s)" means the specific edition of Maintenance Connection's online customer relationship management, billing, data analysis, and other corporate ERP Softwares identified during the ordering process, developed, operated, and maintained by Maintenance Connection accessible via `}<a parentName="p" {...{
        "href": "http://www.maintenanceconnection.ca"
      }}>{`http://www.maintenanceconnection.ca`}</a>{` or another designated web site or IP address or ancillary Softwares rendered to Customer by Maintenance Connection Canada, to which Customer is being granted access under this Agreement, including the Maintenance Connection Technology and the Content;`}</p>
    <p>{`"SMA" means a Support and Maintenance Agreement. To receive upgrades and ongoing support, Customer must have a SMA in place, fully paid for;`}</p>
    <p>{`"System Administrator(s)" means those Users designated by Customer who are authorized to create User accounts and otherwise administer Customer's use of the Software;`}</p>
    <p>{`"User(s)" means  Customer's, employees, representatives, consultants, contractors or agents who are authorized to use the Software and have been supplied user identifications and passwords by Customer (or by Maintenance Connection Canada at Customer's request).`}</p>
    <h2>{`Questions or Additional Information:`}</h2>
    <p>{`If you have questions regarding this Agreement or wish to obtain additional information, please send an e-mail to `}<a parentName="p" {...{
        "href": "mailto:info@maintenanceconnection.ca"
      }}>{`info@maintenanceconnection.ca`}</a>{`.`}</p>
    <h2>{`Trademarks`}</h2>
    <p>{`"Maintenance Connection" and "maintenanceconnection.com" are trademarks or registered trademarks of Maintenance Connection, Inc. in the United States and other countries. In Canada "Maintenance Connection" and "MaintenanceConnection.ca" are trademarks of Maintenance Connection Canada.`}</p>
    <p>{`Last updated: Dec 3, 2013, Effective immediately (2013.12.03) for new customers, effective for all existing Customers as of January 3rd, 2014.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      